/*
<button type="button" class="js-display-popin" data-target="#popin_IDUNIQUE">
	open popin
</button>

<div id="popin_IDUNIQUE" class="popin js-popin">
    <div class="popin__container inner js-popin-content">
        <button type="button" class="popin__close js-popin-close">
            <svg class="icon icon-close popin__close-svg" aria-hidden="true"><use xlink:href="#icon-close" /></svg>
        </button>
        <div class="popin__content">
        	<div class="popin__title">titre popin</div>
        </div>
    </div><!-- popin__container -->
</div><!-- popin -->
*/
var popin = {

	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$displayPopin = $(".js-display-popin");
		this.ui.$popin = $(".js-popin");
		this.ui.$popinClose = $(".js-popin-close");
		this.ui.$popinContainer = $('.js-popin-container');
		this.ui.$popinContent = $('.js-popin-content');
	},

	bindEvents: function() {
		this.ui.$displayPopin.on('click', this.popinOpen.bind(this));
		this.ui.$popinClose.on('click', this.popinClose.bind(this));
		this.ui.$popin.on('click', this.popinClose.bind(this));
		this.ui.$popinContainer.on('click', this.popinClose.bind(this));
		this.ui.$popinContent.on('click', this.popinContentClick.bind(this));
	},

	popinContentClick: function(e) {
		e.stopPropagation();
	},
	popinOpen: function(e) {
		let self = this;
		let $this = $(e.target);
		if(!$this.hasClass("js-display-popin")){
			$this = $this.closest('.js-display-popin');
		}
		let $target = $($this.attr('data-target'));
		e.preventDefault();
		$target.fadeIn(200);
		$("body").addClass("disable-scroll");
	},

    popinClose: function(e) {
		let self = this;
		let $this = $(e.target);
		let $targetPopin;
		e.stopPropagation();
		
		if($this.hasClass("js-popin")){
			$targetPopin = $this;
		} else {
			$targetPopin = $this.closest('.js-popin');
		}
		$targetPopin.fadeOut(200)
		$("body").removeClass("disable-scroll");
    }

};

module.exports = popin;

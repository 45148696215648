import checkVisible from './checkVisible';

const animateOnView = {

    ui: {
    },

    init: function () {
        this.bindUI();
        this.bindEvents();
    },

    bindUI: function () {
        this.ui.$document = $(document);
        this.ui.$toAnimate = $('.js-animate-on-view');
        this.onView();
    },

    bindEvents: function () {
        this.ui.$document.on('scroll', this.onView.bind(this));
    },

    onView: function () {
        if (this.ui.$toAnimate.length > 0){
            for(var i = 0, len = this.ui.$toAnimate.length; i < len; i++){
                
                // let threshold = this.ui.$toAnimate.eq(i).attr("data-animate-threshold")
                let threshold = (this.ui.$toAnimate.eq(i).hasClass("js-animate-on-view-early")) ? 200 : window.innerHeight/3 ;
                
                if (!this.ui.$toAnimate.eq(i).hasClass("animate") && checkVisible(this.ui.$toAnimate.eq(i)[0], threshold)) {
                    this.ui.$toAnimate.eq(i).addClass("animate");
                    this.ui.$document.unbind('scroll', this.onView);
                    var $round = this.ui.$toAnimate.eq(i).find('.js-round')
                    if($round.length > 0){
                        $round.each(function(){
                            var $this = $(this);
                            var $circle = $this.find('.js-circle')
                            var roundRadius = $circle.attr('r');
                            var roundPercent = $this.data('percent');
                            var roundCircum = 2 * roundRadius * Math.PI;
                            var roundDraw = roundPercent * roundCircum / 100;
                            $circle.css('stroke-dasharray', roundDraw  + ' 999')
                        });
                    }
                }
            }
        }
    },
};

module.exports = animateOnView;
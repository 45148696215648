/* usage sample :
<a href="https://www.facebook.com/sharer/sharer.php?u={{ current_url }}" target="_blank" title="{{ _x('Share on Facebook (new window)', 'Front - Share on Facebook', '366') }}" class="bannerShare__btn js-facebook-share js-tac-share" data-tacid="{{ fbId }}">
    {% include "ui/svg/facebook.svg" %}
</a>
<a href="http://www.twitter.com/share?url={{ current_url }}" target="_blank" title="{{ _x('Share on Twitter (new window)', 'Front - Share on Twitter', '366') }}" class="bannerShare__btn js-twitter-share js-tac-share" data-tacid="{{ twId }}">
    {% include "ui/svg/twitter.svg" %}
</a>
{% if share_title is empty %}
    {% set share_title = post.title %}
{% endif %}
<a href="https://www.linkedin.com/shareArticle?mini=true&url={{ current_url }}&title={{ share_title }}{% if share_description is not empty %}&summary={{ share_description }}{% endif %}&source={{ site.name }}" target="_blank" title="{{ _x('Share on Linkedin (new window)', 'Front - Share on Linkedin', '366') }}" class="bannerShare__btn js-linkedin-share js-tac-share" data-tacid="{{ inId }}">
    {% include "ui/svg/linkedin.svg" %}
</a>
<a href="mailto:?subject={{ share_title }}&body={% if share_description is not empty %}{{ share_description }}{% endif %} - {{ current_url  }}" title="{{ _x('Share by mail', 'Front - Share by Mail', '366') }}" class="bannerShare__btn">
    {% include "ui/svg/contact.svg" %}
</a>

<a href="mailto:?subject={{ share_title }}&body={% if share_description is not empty %}{{ share_description }}{% endif %} - {{ current_url  }}" target="_blank" title="{{ _x('Share by mail', 'Front - Share by Mail', '366') }}" class="shareIcon__btn">
    <svg class="icon icon-mail" aria-hidden="true"><use xlink:href="#icon-mail" /></svg>
</a>

<button type="button" class="button js-copy-link">Copier l'Url de l'article</button>
<textarea class="js-copy-link-textarea hide">{{ current_url }}</textarea>
*/
const shareLinks = {

    init: function init() {
	    $('.js-facebook-share').on("click",function(event) {
            var width  = 575,
                height = 500,
                left   = ($(window).width()  - width)  / 2,
                top    = ($(window).height() - height) / 2,
                url    = $(this).attr("href"),
                opts   = 'status=1' +
                    ',width='  + width  +
                    ',height=' + height +
                    ',top='    + top    +
                    ',left='   + left;
            window.open(url, 'facebook', opts);
	        return false;
	    });
	    $('.js-linkedin-share').on("click",function(event) {
            var width = 575,
                height = 500,
                left = ($(window).width() - width) / 2,
                top = ($(window).height() - height) / 2,
                url = $(this).attr("href"),
                opts = 'status=1' +
                    ',width=' + width +
                    ',height=' + height +
                    ',top=' + top +
                    ',left=' + left;
            window.open(url, 'linkedin', opts);
	        return false;
	    });
	    $('.js-twitter-share').on("click",function(event) {
            var width = 575,
                height = 260,
                left = ($(window).width() - width) / 2,
                top = ($(window).height() - height) / 2,
                url = $(this).attr("href"),
                opts = 'status=1' +
                    ',width=' + width +
                    ',height=' + height +
                    ',top=' + top +
                    ',left=' + left;
            window.open(url, 'twitter', opts);
	        return false;
	    });

        $(".js-copy-link").on("click", function(e){
            e.preventDefault();
            var copyTextarea = document.querySelector('.js-copy-link-textarea');
            copyTextarea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                if("successful"){
                    alert("Lien copié");
                    // $(".js-textareacopyMsg").html("Lien copié").show();
                    // if(idTimeoutMsgCopy != ""){
                    //  clearTimeout(idTimeoutMsgCopy);
                    // }
                    // idTimeoutMsgCopy = setTimeout(function(){
                    //  $(".js-textareacopyMsg").fadeOut(500);
                    // },5000);
                }else{
                    alert("Veuillez selectionner le texte et le copier manuellement");
                    // $(".js-textareacopyMsg").html("Veuillez selectionner le texte et le copier manuellement").show();
                    // if(idTimeoutMsgCopy != ""){
                    //  clearTimeout(idTimeoutMsgCopy);
                    // }
                    // idTimeoutMsgCopy = setTimeout(function(){
                    //  $(".js-textareacopyMsg").fadeOut(500);
                    // },5000);
                }
            } catch (err) {
                $(".js-textareacopyMsg").html("Veuillez selectionner le texte et le copier manuellement").show();
                // if(idTimeoutMsgCopy != ""){
                //  clearTimeout(idTimeoutMsgCopy);
                // }
                // idTimeoutMsgCopy = setTimeout(function(){
                //  $(".js-textareacopyMsg").fadeOut(500);
                // },5000);
            }
        });
	},

};

module.exports = shareLinks;
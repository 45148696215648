const materialForm = {

	ui: {},

	init: function() {
        // console.log('material-form');

		this.bindUI();
		this.bindEvents();
		let that = this
		this.ui.$inputs.each(function(){
			that.toggleHasValueClass($(this));
		});
	},
	
	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$inputs = $("input, textarea, select");
	},

	bindEvents: function() {
		this.ui.$inputs.on('focusout', this.onFocusOut.bind(this));
		this.ui.$inputs.on('change', this.onChange.bind(this));
	},

	onChange: function(e) {
		let self = this;
		let $this = $(e.target);
        if($this.val() && $this.val().length > 0){
            $this.closest('.gfield').removeClass("gfield_error");
        }
	},
	onFocusOut: function(e) {
		let self = this;
		let $this = $(e.target);
		this.toggleHasValueClass($this)
	},

    toggleHasValueClass: function($this) {
        if($this.val() && $this.val().length > 0){
            $this.addClass("has-value");
        } else {
            $this.removeClass("has-value");
        }
    }

};

module.exports = materialForm;

var sliderMarques = {

	ui: {},

	init: function() {
		this.bindUI();
		let that = this;
		this.ui.$slide.parent().each(function(){
	        that.ui.$slide.sort(function(){
	            return Math.random() - 0.5;
	        }).detach().appendTo(that.ui.$slider);
	    });
		this.bindEvents();
		this.initSlider();
	},

	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$slider = $(".js-sliderMarques");
		this.ui.$slide = $(".js-sliderMarques__slide");
		this.ui.$region = $(".js-svgMapRegion-region");

	},

	bindEvents: function() {
		this.ui.$region.on('click', this.regionClick.bind(this));
	},

	// popinContentClick: function(e) {
	// 	e.stopPropagation();
	// },
	regionClick: function(e) {
		let self = this;
		let $this = $(e.currentTarget);
		let region = $this.attr("data-region")
		let index = $(".js-sliderMarques__slide[data-region="+region+"]").closest(".slick-slide").attr("data-slick-index");
		this.ui.$slider.slick('slickGoTo',index);
	},
	initSlider: function() {
		this.ui.$slider.slick({
			// infinite: true,
			slidesToShow: 1.2,
			infinite: false,
			// variableWidth: true,
			// slidesToScroll: 3,
			centerMode: false,
			prevArrow: "<button type='button' class='slick-prev btn__slider btn__slider-prev'><svg class='icon icon-carret-left ' aria-hidden='true'><use xlink:href='#icon-carret-left'></use></svg></button>",
			nextArrow: "<button type='button' class='slick-next btn__slider btn__slider-next'><svg class='icon icon-carret-right' aria-hidden='true'><use xlink:href='#icon-carret-right'></use></svg></button>",
		});
	},

};

module.exports = sliderMarques;

const header = {
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function bindUI() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);
		this.ui.$body = $("body");

		this.ui.$header = $(".js-header");

		this.ui.$menuToggle = $(".js-toggle-menu");
		this.ui.$menuContainer = $(".js-menu-container");
		this.ui.$menuNav = $(".js-menu-nav");
		this.ui.$subMenuNavLink = $(".js-subMenu-nav a");
		this.ui.$toggleSubMenuNav = $(".js-toggle-subMenu-nav");

		this.ui.$openSubMenu = $(".js-openSubMenu");
		this.ui.$closeSubMenu = $(".js-closeSubMenu");
	},

	bindEvents: function bindEvents() {
		// this.ui.$window.on('scroll', this.menuOnScroll.bind(this));
		
		this.ui.$menuToggle.on('click', this.menuMobile.bind(this));
		this.ui.$subMenuNavLink.on('click', this.menuMobile.bind(this));
		this.ui.$openSubMenu.on('click', this.openSubMenu.bind(this));
		this.ui.$closeSubMenu.on('click', this.closeSubMenu.bind(this));
		this.ui.$toggleSubMenuNav.on('click', this.toggleSubMenuNav.bind(this));
	},

	openSubMenu: function(e) {
		let self = this;
		let $this = $(e.target);
		if($this.closest(".js-mainMenu__item").hasClass("openSubMenu")){
			$this.closest(".js-mainMenu__item").removeClass("openSubMenu");
			this.ui.$header.removeClass("header--openSubMenu");
			// this.ui.$body.removeClass("disable-scroll");
		}else{
			$(".js-mainMenu__item").removeClass("openSubMenu");
			$this.closest(".js-mainMenu__item").addClass("openSubMenu");
			this.ui.$header.addClass("header--openSubMenu");
			// this.ui.$body.addClass("disable-scroll");
		}
	},
	closeSubMenu: function(e) {
		let self = this;
		let $this = $(e.target);
		// this.ui.$body.removeClass("disable-scroll");
		this.ui.$header.removeClass("header--openSubMenu");
		$this.closest(".js-mainMenu__item").removeClass("openSubMenu");
	},

	toggleSubMenuNav: function(e) {
		if(window.innerWidth<1520){
			e.preventDefault();
			let self = this;
			let $this = $(e.target);
			$this.parent().find(".js-subMenu-nav").toggleClass("open")
		}
	},

	menuOnScroll: function(e) {
		let self = this;
		let $this = $(e.target);
		
		if($this.scrollTop() > 50 && !this.ui.$header.hasClass("header--smaller")) {
			$(".js-header").addClass("header--smaller");
		}
		if($this.scrollTop() === 0 && this.ui.$header.hasClass("header--smaller")) {
			$(".js-header").removeClass("header--smaller");
		}
	},

    menuMobile: function(e) {
		let $this = this.ui.$menuToggle;
		
		if($this.hasClass("active")){
			this.ui.$body.removeClass("disable-scroll");
			$this.removeClass("active");
			this.ui.$menuContainer.removeClass("active");
			this.ui.$menuNav.removeClass("active");
		} else {
			this.ui.$body.addClass("disable-scroll");
			$this.addClass("active");
			this.ui.$menuContainer.addClass("active");
			this.ui.$menuNav.addClass("active");
		}
    }

};

module.exports = header;

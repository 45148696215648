const newsletter = {
	
	ui: {},

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$newsletter = $('.js-newsletter-form');
		this.ui.$successMessage = $('.js-newsletter-success-message');
	},

	bindEvents: function() {
		this.ui.$newsletter.on('submit', this.newsletterSubscribe.bind(this));
	},


	newsletterSubscribe: function(e) {
		e.preventDefault();
		let $this = $(e.currentTarget);
		let $submitIcon = $this.find('.js-newsletter-submit-icon');
		let $ajaxLoader = $this.find('.js-ajaxLoader');


		if (!$this.hasClass('is-submitting')) {

			$this.addClass('is-submitting');
			$submitIcon.hide();
			$ajaxLoader.show();

			$.ajax({
				url: wordpress_vars.ajax_url,
				type: 'POST',
				data: {
					'action': 'newsletter_subscribe',
					'user_email': $this.find('input[name=newsletter_email]').val()
				},
				success: function(html){
					$this.removeClass('is-submitting');
					$('.js-newsletter-success-message').show();
					$submitIcon.show();
					$ajaxLoader.hide();
				},
				error: function(data){
					$this.removeClass('is-submitting');
					$('.js-newsletter-success-message').hide();
					$submitIcon.show();
					$ajaxLoader.hide();
				}
			});

		}
	},
};

module.exports = newsletter;
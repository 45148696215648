// Import jQuery => utilise celui de wp de base, evite duplicat de jquery
// import jQuery from "jquery";
// export for others scripts to use
window.$ = window.jQuery = jQuery;

// Require modules
import browsetClassHelpers from './modules/browser-class-helpers.js';
import header from './modules/header.js';
import materialForm from './modules/material-form.js';
import navScrollHighlight from './modules/nav-scroll-highlight.js';
import popin from './modules/popin.js';
import scrollAnchor from './modules/scroll-anchor.js';
import ssTooltip from './modules/tooltip';
import sliderMarques from './modules/sliderMarques';
import sliderBestCases from './modules/sliderBestCases';
import animateOnView from './modules/animateOnView';
import shareLinks from './modules/shareLinks';
import newsletter from './modules/newsletter';

// import checkVisible from './modules/checkVisible';

// Import Slick
// import 'slick-carousel';

$(function(){
	$("html").removeClass("no-js");

	browsetClassHelpers.init();
	
	header.init();

	shareLinks.init();

	if ($(".js-materialForm").length) {
		materialForm.init();
	}
	if ($(".js-sliderMarques").length) {
		sliderMarques.init();
	}
	if ($(".js-sliderBestCases").length) {
		sliderBestCases.init();
	}
	if ($(".js-scroll-anchor").length) {
		scrollAnchor.init();
	}

	if ($(".js-animate-on-view").length) {
		animateOnView.init();
	}

	if ($(".js-newsletter-form").length) {
		newsletter.init();
	}

	if ($(".js-largeMedia").length) {
		$(".js-largeMedia").on("click",function(){
			var $iframe = $(this).find("iframe");
			var $video = $(this).find(".js-largeMedia-video");
			var src = $iframe.attr('src');
			if(!$(this).hasClass("is-playing")){
				if($video.length){
					$video[0].play();
					$video.attr("controls",1)
				}
				if($iframe.length){
					$iframe.attr("src", src+"?autoplay=1");
				}
				$(this).addClass("is-playing");
			}
		});
	}
	if ($(".js-marquesLogoSlider").length) {
		$(".js-marquesLogoSlider").slick({
			infinite: true,
			variableWidth: true,
			// slidesToScroll: 3,
			prevArrow: "",//"<button type='button' class='slick-prev btn__slider btn__slider-prev'><svg class='icon icon-arrow-left' viewBox='0 0 32 32'><path opacity='0.3' d='M29.867 14.933v0c0-1.28-0.853-2.133-2.133-2.133h-25.6c-1.28 0-2.133 0.853-2.133 2.133v0c0 1.28 0.853 2.133 2.133 2.133h25.6c1.28 0 2.133-0.853 2.133-2.133z'></path><path d='M16.427 26.24c0.64 0.64 0.853 1.28 0.64 2.133s-0.853 1.28-1.493 1.493c-0.64 0.213-1.493 0-2.133-0.64l-12.8-12.8c-0.853-0.853-0.853-2.133 0-2.987l11.733-12.8c0.427-0.64 1.28-0.853 2.133-0.64 0.64 0.213 1.28 0.64 1.493 1.493 0.213 0.64 0 1.493-0.427 2.133l-10.453 11.307 11.307 11.307z'></path></svg></button>",
			nextArrow: "",//"<button type='button' class='slick-next btn__slider btn__slider-next'><svg class='icon icon-arrow-right' viewBox='0 0 32 32'><path opacity='0.3' d='M0 16v0c0-1.371 0.914-2.286 2.286-2.286h27.429c1.371 0 2.286 0.914 2.286 2.286v0c0 1.371-0.914 2.286-2.286 2.286h-27.429c-1.371 0-2.286-0.914-2.286-2.286z'></path><path d='M14.4 28.114c-0.686 0.686-0.914 1.371-0.686 2.286 0.229 0.686 0.914 1.371 1.6 1.6s1.6 0 2.286-0.686l13.714-13.714c0.914-0.914 0.914-2.286 0-3.2l-12.571-13.714c-0.457-0.686-1.371-0.914-2.286-0.686s-1.371 0.686-1.6 1.6c-0.229 0.686 0 1.6 0.457 2.286l11.2 12.114-12.114 12.114z'></path></svg></button>",

		});
	}
	if($(".js-accordeon").length){
		$(".js-accordeon-toggle").click(function(){
			$(this).toggleClass("open")
			$(this).closest(".js-accordeon").find(".js-accordeon-content").slideToggle();
		});
	}

	if (window.randomHero != undefined && window.randomHero.length > 0 ) {
		var $hero__visual = $(".js-hero__visual")
		var random = Math.floor(Math.random() * window.randomHero.length)
		$hero__visual.find("source").attr('srcset', window.randomHero[random]['srcset']);
		$hero__visual.find("img").attr('src', window.randomHero[random]['src']);
	}

	// if ($(".js-nav-link").length) {
	// 	navScrollHighlight.init();
	// }
	// if ($(".js-popin").length) {
	// 	popin.init();
	// }
	// if ($('.js-tooltip').length) {
	// 	ssTooltip.init();
	// }
});

var sliderBestCases = {

	ui: {},

	init: function() {
		this.bindUI();
		let that = this;
		this.ui.$slide.parent().each(function(){
	        that.ui.$slide.sort(function(){
	            return Math.random() - 0.5;
	        }).detach().appendTo(that.ui.$slider);
	    });
		this.bindEvents();
		this.initSlider();
	},

	bindUI: function() {
		this.ui.$document = $(document);

		this.ui.$slider = $(".js-sliderBestCases");
		this.ui.$slide = $(".js-sliderBestCases__slide");

	},

	bindEvents: function() {
		// this.ui.$region.on('click', this.regionClick.bind(this));
	},

	initSlider: function() {
		this.ui.$slider.slick({
			// infinite: true,
			slidesToShow: 3.2,
			slidesToScroll: 3,
			infinite: false,
			variableWidth: true,
			// slidesToScroll: 3,
			centerMode: false,
			prevArrow: "",//"<button type='button' class='slick-prev btn__slider btn__slider-prev'><svg class='icon icon-arrow-left' viewBox='0 0 32 32'><path opacity='0.3' d='M29.867 14.933v0c0-1.28-0.853-2.133-2.133-2.133h-25.6c-1.28 0-2.133 0.853-2.133 2.133v0c0 1.28 0.853 2.133 2.133 2.133h25.6c1.28 0 2.133-0.853 2.133-2.133z'></path><path d='M16.427 26.24c0.64 0.64 0.853 1.28 0.64 2.133s-0.853 1.28-1.493 1.493c-0.64 0.213-1.493 0-2.133-0.64l-12.8-12.8c-0.853-0.853-0.853-2.133 0-2.987l11.733-12.8c0.427-0.64 1.28-0.853 2.133-0.64 0.64 0.213 1.28 0.64 1.493 1.493 0.213 0.64 0 1.493-0.427 2.133l-10.453 11.307 11.307 11.307z'></path></svg></button>",
			nextArrow: "",//"<button type='button' class='slick-next btn__slider btn__slider-next'><svg class='icon icon-arrow-right' viewBox='0 0 32 32'><path opacity='0.3' d='M0 16v0c0-1.371 0.914-2.286 2.286-2.286h27.429c1.371 0 2.286 0.914 2.286 2.286v0c0 1.371-0.914 2.286-2.286 2.286h-27.429c-1.371 0-2.286-0.914-2.286-2.286z'></path><path d='M14.4 28.114c-0.686 0.686-0.914 1.371-0.686 2.286 0.229 0.686 0.914 1.371 1.6 1.6s1.6 0 2.286-0.686l13.714-13.714c0.914-0.914 0.914-2.286 0-3.2l-12.571-13.714c-0.457-0.686-1.371-0.914-2.286-0.686s-1.371 0.686-1.6 1.6c-0.229 0.686 0 1.6 0.457 2.286l11.2 12.114-12.114 12.114z'></path></svg></button>",

			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
			]
		});
	},

};

module.exports = sliderBestCases;

var scrollAnchor = {
	
	ui: {},

	initialOffset: 0,
	addToInitialOffset: null,

	init: function() {
		this.bindUI();
		this.bindEvents();
	},

	bindUI: function() {
		this.ui.$window = $(window);
		this.ui.$document = $(document);

		this.ui.$linkScrollAnchor = $('a.js-scroll-anchor');
	},

	bindEvents: function() {
		this.ui.$linkScrollAnchor.on('click', this.onClickAnchor.bind(this));
	},

	onClickAnchor: function(e) {
		let self = this;
		let $this = $(e.target);

		e.preventDefault();
		if (this.ui.$window.width() < 1080) {
			this.addToInitialOffset = 60;
		} else {
			this.addToInitialOffset = this.initialOffset;
		}
		$('html, body').animate({ 
			scrollTop: $($this.attr('href')).offset().top - self.addToInitialOffset 
		}, 300);
	},

};

module.exports = scrollAnchor;
